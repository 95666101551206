import NavBar from "@/components/NavBar";
import authModel from "@/models/auth.model";
import globalModel from "@/models/global.model";
import { REGEX_ID_CARD } from "@/utils/regex";
import { privacyString } from "@/utils/string";
import { useReactive } from "ahooks";
import { useNavigate } from "react-router-dom";
import { Button, Field, Form, NumberKeyboard, Toast } from "react-vant";
import { useSnapshot } from "valtio";
import styles from "./index.module.css";

export default () => {
  const nav = useNavigate();
  const { user } = useSnapshot(globalModel.state);
  const state = useReactive({
    visible: false,
  });
  const [form] = Form.useForm();
  const idCard = Form.useWatch("idCard", form);
  const onFinish = async (values: any) => {
    const update = Toast({
      type: "loading",
      message: "正在认证...",
      forbidClick: true,
      duration: 0,
    });
    const res = await authModel.realname({
      ...values,
    });
    if (res?.code === 200) {
      update.config({
        type: "success",
        message: "认证成功",
        forbidClick: true,
      });
      await globalModel.getOwner();
      nav(-1);
    }
    setTimeout(() => {
      update.clear();
    }, 2000);
  };
  return (
    <div className={styles.page}>
      <NavBar title="认证" />

      <Form
        className={styles.form}
        layout="vertical"
        border={false}
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          label="姓名"
          name="realname"
          rules={[{ required: true, message: "请输入姓名" }]}
        >
          <Field placeholder="请输入您的真实姓名" />
        </Form.Item>
        <Form.Item
          label="身份证号"
          name="idCard"
          rules={[
            { required: true, message: "请输入您的身份证号" },
            {
              validator: async (_, value) => {
                try {
                  if (!value) {
                    throw new Error("请输入身份证号");
                  }
                  if (!REGEX_ID_CARD.test(value)) {
                    throw new Error("身份证号格式不正确");
                  }
                } catch (err: any) {
                  return Promise.reject(err);
                }
              },
            },
          ]}
        >
          <Field
            placeholder="请输入身份证号"
            clearable
            readOnly
            onClick={() => (state.visible = true)}
          />
        </Form.Item>
      </Form>

      <NumberKeyboard
        theme="custom"
        closeButtonText="完成"
        extraKey="X"
        visible={state.visible}
        onInput={(v) => {
          form.setFieldValue("idCard", `${idCard ?? ""}${v}`);
        }}
        onDelete={() => {
          const str = idCard.toString();
          form.setFieldValue("idCard", `${str.slice(0, str.length - 1) ?? ""}`);
        }}
        onHide={() => (state.visible = false)}
        onClose={() => (state.visible = false)}
        onBlur={() => (state.visible = false)}
      />

      <div className={styles.footer}>
        <Button
          className={styles.submit}
          type="primary"
          block
          onClick={async () => {
            try {
              await form.validateFields();
              form.submit();
            } catch (e: any) {
              if (e && e.errorFields) {
                Toast({
                  message: e.errorFields[0]?.errors[0],
                  position: "top",
                });
              }
            }
          }}
        >
          立即认证
        </Button>
      </div>
    </div>
  );
};
